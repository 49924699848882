/* General Styles for Hero Container */
.hero-container {
  position: relative;
  height: 120vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;
  background-color: #FFFDD0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

/* Hero Content Wrapper - Two Columns Layout */
.hero-content {
  display: flex;
  align-items: center;
  width: 90%; /* Reduced width to prevent overflow */
  max-width: 1200px;
  padding: 0 20%; /* Consistent padding on both sides */
}

/* Hero Image Column */
.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  z-index: 4;
}


.buddy-graphic {
  width: 500px;
  filter: saturate(0.7) brightness(1.1);
}

/* Hero Text Column */
.hero-text {
  flex: 1;
  max-width: 500px;
  text-align: left;
}

.hero-text h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #f24183; /* Default color for the entire text */
  font-weight: bold;
}

.hero-text h2 em {
  color: #061f33; /* Unique color for the highlighted word */
  font-style: normal; /* Removes italic style */
}

.hero-text h3 {
  font-size: 1rem;
  margin-bottom: 20px;
}

/* Hero Button */
.hero-button {
  margin-top: 20px;
}

.hero-button button {
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #f24183;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
}

.play-icon {
  margin-right: 12px;
  vertical-align: middle;
  transition: transform 0.2s ease;
}

/* Accessibility: Focus Styles */
.hero-button button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 127, 80, 0.5);
}

/* Background Graphics */
.top-left-graphic {
  position: absolute;
  width: 300px;
  transform: translateY(var(--dynamic-translate-left)) rotate(-10deg) rotate(var(--dynamic-rotation-left));
  top: 5%;
  left: 2%;
  rotate: -30deg;
}
.bottom-left-graphic {
  position: absolute;
  width: 300px;
  transform: translateY(var(--dynamic-translate-left)) rotate(-30deg) rotate(var(--dynamic-rotation-left));
  top: 50%;
  left: -6%;
  rotate: 30deg;
}

.right-graphic {
  position: absolute;
  width: 300px;
  transform: translateY(var(--dynamic-translate-right)) rotate(-30deg) rotate(var(--dynamic-rotation-right));
  top: 20%;
  right: -6%;
}


/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .hero-container {
    height: auto;
    padding-bottom: 40px; /* Add padding for better spacing on mobile */
  }

  .hero-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 10%;
  }

  .buddy-graphic {
    width: 400px;
  }

  .hero-text h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .hero-text {
    text-align: center;
    max-width: 300px;
  }

  .hero-button {
    display: flex;
    justify-content: center;
  }

  .hero-button button {
    padding: 12px 20px;
    font-size: 1.5rem;
  }

  /* Resize graphics for smaller screens */
  .top-left-graphic,
  .bottom-left-graphic,
  .right-graphic {
    width: 150px;
  }

  .top-left-graphic {
    top: 2%;
    left: -5%;
  }

  .bottom-left-graphic {
    top: 40%;
    left: -10%;
  }

  .right-graphic {
    top: 15%;
    right: -10%;
  }
}



/* Fun Effects */
.hero-button button:hover .play-icon {
  transform: translateX(5px); /* Slight move to the right */
}

/* Bounce Effect on Hover */
.hero-button button:hover {
  animation: bounce 0.6s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

/* Satisfying Click (Active) Animation */
.hero-button button:active {
  transform: scale(0.95); /* Slightly shrink the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Reduced shadow */
}

/* Keyframes for Bounce Animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
