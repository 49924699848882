.getyourbuddy-container {
  position: relative;
  height: 140vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #061f33; /* Dark background to make stars visible */
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
  margin-top: -100px;
  padding-top: 100px;
}

/* Shooting Stars Effect */
.shooting-star {
  position: absolute;
  top: -20px;
  width: 2px;
  height: 800px;
  background: linear-gradient(45deg, #ffffff, rgba(255, 255, 255, 0));
  opacity: 0.6;
  animation: shooting-star-animation 8s linear infinite;
  transform: translateY(-100px) translateX(0) rotate(45deg);
  z-index: 0; /* Ensure stars stay behind the main content */
}

.shooting-star:nth-child(1) {
  left: 20%;
  animation-delay: 2s;
  animation-duration: 10s;
}

.shooting-star:nth-child(2) {
  left: 60%;
  animation-delay: 5s;
  animation-duration: 8s;
}

.shooting-star:nth-child(3) {
  left: 80%;
  animation-delay: 3s;
  animation-duration: 12s;
}

@keyframes shooting-star-animation {
  0% {
    transform: translateY(-100px) translateX(0) rotate(45deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) translateX(-200px) rotate(45deg);
    opacity: 0;
  }
}

.big-shooting-star {
  position: absolute;
  top: -50px;
  left: 30%;
  width: 2px;
  height: 800px;
  background: linear-gradient(45deg, #ffd700, rgba(255, 215, 0, 0));
  opacity: 0.8;
  animation: big-shooting-star-animation 15s linear infinite;
  transform: translateY(-150px) translateX(0) rotate(45deg);
  z-index: 0;
}

.star-head {
  position: absolute;
  top: -25px;
  left: -20px;
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, #ffd700, #fff);
  clip-path: polygon(
    50% 0%, 61% 35%, 98% 35%, 68% 57%, 
    79% 91%, 50% 70%, 21% 91%, 32% 57%, 
    2% 35%, 39% 35%
  ); /* Draws a star shape */
  animation: spin 3s linear infinite;
}

/* Big Shooting Star Animation */
@keyframes big-shooting-star-animation {
  0% {
    transform: translateY(-150px) translateX(0) rotate(45deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) translateX(-300px) rotate(45deg);
    opacity: 0;
  }
}

/* Star Head Spinning Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.getyourbuddy-content {
  text-align: center;
  justify-content: center;
  z-index: 2;
  max-width: 1000px;
  padding: 20px;
}

.getyourbuddy-title {
  font-size: 2.5rem;
  color: #f0faff;
  font-weight: bold;
  margin-bottom: 10px;
}

.getyourbuddy-description {
  font-size: 1.2rem;
  color: #7f8c8d; /* Light grey for readability */
  margin-bottom: 20px;
}

.getyourbuddy-graphic {
  width: 450px;
}

.links-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Ensures wrap on smaller screens */
}

.links-container button {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  padding: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 200px;
  justify-content: center;
}

/* Animation for jumping up-and-down shake */
@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Moves button up slightly */
  }
}

/* Apply jump animation to buttons */
.lineoa-button,
.tiktok-button,
.instagram-button {
  animation: jump 2s ease-in-out infinite;
  animation-delay: calc(0.4s * var(--button-index));
}

.button-icon {
  width: 24px;
  height: 24px;
}

/* LINE OA Button */
.lineoa-button {
  background-color: #214c6e;
  color: white;
}

.lineoa-button:hover {
  background-color: #026e02;
}

/* TikTok Button */
.tiktok-button {
  background-color: #214c6e;
  color: white;
}

.tiktok-button:hover {
  background-color: #d81b3f;
}

/* Instagram Button */
.instagram-button {
  background-color: #214c6e;
  color: white;
}

.instagram-button:hover {
  background-color: #6a2a8c;
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
  .links-container {
    flex-direction: column; /* Align buttons vertically */
    align-items: center;
    gap: 15px;
  }

  .getyourbuddy-graphic {
    width: 350px;
  }

  .getyourbuddy-title {
    font-size: 2rem;
  }

  .getyourbuddy-description {
    font-size: 1rem;
  }
}
