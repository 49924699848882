html, body {
    height: 100%;
    margin: 0;
    background-color: black; /* Dark background to match your app theme */
}

.app {
    font-family: 'Prompt', sans-serif; /* Use the Prompt font */
}
  
/* Floating Language Switch Button */
.floating-lang-switch {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #061f33;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1000;
}
  
.floating-lang-switch:hover {
    background-color: #061f33;
    color: #ffffff;
    transform: translateY(-3px);
}
