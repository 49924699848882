/* General Styles for Manual Container */
.manual-container {
  position: relative;
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0faff; /* Soft blue tone to contrast other sections */
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding-top: 100px;
  margin-top: -100px;

}

/* Manual Content Wrapper */
.manual-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  text-align: center;
  z-index: 2;
  gap: 30px;
}

.manual-text h2 {
  font-size: 2.5rem;
  color: #34495e; /* Dark blue-grey */
  font-weight: bold;
  margin-bottom: 15px;
}

/* Features Showcase Styling */
.features-showcase {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  width: 50px;
  height: auto;
  margin-bottom: 15px;
  color: #f24183;
}

.feature-card h4 {
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 1rem;
  color: #7f8c8d;
}

/* Download Manual Button */
.download-manual {
  margin-top: 30px;
}

.manual-download-button {
  padding: 15px 25px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #214c6e;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.manual-download-button:hover {
  background-color: #f24183;
}

/* Background Decorative Image */
.manual-top-left-graphic {
  position: absolute;
  width: 300px;
  transform: translateY(var(--dynamic-translate-left)) rotate(-10deg) rotate(var(--dynamic-rotation-left));
  top: 5%;
  left: 2%;
  rotate: -30deg;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .manual-container {
    padding-bottom: 40px;
  }

  .manual-content {
    padding-top: 100px;
  }

  .features-showcase {
    gap: 20px;
  }

  .feature-card {
    width: 80%;
  }

  .manual-top-left-graphic {
    width: 150px;
    top: 10%;
    left: -5%;
  }
}
