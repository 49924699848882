/* Footer.css */
.footer-container {
    background-color: black;
    color: #f0faff;
    padding: 30px 20px;
    font-family: 'Prompt', sans-serif;
    z-index: 0;
    margin-top: -100px;
  }
  
  .footer-content {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  
  .business-info h3 {
    margin: 0 0 10px 0;
  }
  
  .business-info a {
    color: #f0faff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .business-info a:hover {
    color: #ffd700;
  }
  
  .legal-links {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .legal-links a {
    color: #f0faff;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .legal-links a:hover {
    color: #ffd700;
  }
  
  .social-media-links {
    display: flex;
    gap: 20px;
  }
  
  .social-media-links a {
    color: #f0faff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-media-links a:hover {
    color: #ffd700;
  }
  
  .footer-bottom-bar {
    margin-top: 20px;
    border-top: 1px solid #f0faff;
    padding-top: 15px;
    font-size: 0.85rem;
    color: #7f8c8d;
  }
  